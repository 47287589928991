import { useEffect, useState } from 'react';

import { setTimeoutBrowserFriendly } from '../../helper/browser-friendly-timeout';
import styles from '../../styles/export-variables.module.scss';

export const useModalAnimation = (
  dialogRef: React.RefObject<HTMLDialogElement>,
  showModal: boolean,
) => {
  const [animatedHide, setAnimatedHide] = useState(false);

  useEffect(() => {
    if (dialogRef.current) {
      if (showModal) {
        dialogRef.current.showModal();
      } else if (!showModal) {
        setAnimatedHide(true);
        dialogRef.current.classList.add('hide');
      }
    }
  }, [showModal, dialogRef]);

  useEffect(() => {
    if (animatedHide) {
      setAnimatedHide(false);
      setTimeoutBrowserFriendly(() => {
        if (dialogRef.current) {
          dialogRef.current.style.opacity = '0';
        }
      }, parseInt(styles.transitionDuration));

      setTimeoutBrowserFriendly(
        () => {
          if (dialogRef.current) {
            dialogRef.current.classList.remove('hide');
            dialogRef.current.close();
          }
        },
        parseInt(styles.transitionDuration) + 10,
      );
    }
  }, [animatedHide, dialogRef]);
};
