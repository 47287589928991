import { Grid2 as Grid, styled } from '@mui/material';

import {
  MarkdownContainer,
  markdownTextLinkStyle,
} from '../markdown/markdown-container';

type Props = {
  image: React.ReactElement;
  name: string;
  content: string;
};

export const PersonTile = (item: Props) => {
  return (
    <Grid
      size={{ xs: 12, sm: 4, md: 3 }}
      sx={{ marginBottom: 'var(--spacer-xl)' }}
    >
      {item.image}
      <Name>{item.name}</Name>
      <Content html={item.content} />
    </Grid>
  );
};

export default PersonTile;

const Name = styled('h6')(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.fontWeightMedium,
  margin: '10px 0',
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    margin: '20px 0 5px',
  },

  [theme.breakpoints.up('lg')]: {
    margin: '20px 0 10px',
  },
}));

const Content = styled(MarkdownContainer)(({ theme }) => ({
  marginBottom: 0,
  textAlign: 'center',

  '> p': {
    margin: 0,

    strong: {
      fontWeight: theme.typography.fontWeightMedium,
    },

    a: {
      ...markdownTextLinkStyle,
      color: 'var(--color-dark-coal)',
      textDecorationColor: 'var(--color-dark-coal)',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  [theme.breakpoints.up('sm')]: {
    margin: 0,
  },
}));
