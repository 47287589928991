import React from 'react';

import { Button, ButtonTypeMap, Grid2 as Grid, styled } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

import ResponsiveGrid from '../../../layout/grid';

type Props<T extends React.ElementType> = {
  headline: string;
  subline: string;
  link: {
    component: T;
    label: string;
    props: OverrideProps<ButtonTypeMap, T>;
  };
};

export const ShortTextTeaser = <T extends React.ElementType>({
  headline,
  subline,
  link,
}: Props<T>) => (
  <ResponsiveGrid>
    <Grid size={12} sx={{ textAlign: 'center' }}>
      <Headline>{headline}</Headline>
      <Subline>{subline}</Subline>
      <Button
        {...link.props}
        component={link.component}
        variant="contained"
        color="primary"
      >
        {link.label}
      </Button>
    </Grid>
  </ResponsiveGrid>
);

export default ShortTextTeaser;

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  margin: 0,
  marginBottom: 'var(--spacer-l)',
}));

const Subline = styled('h5')(({ theme }) => ({
  ...theme.typography.h5,
  margin: 0,
  marginBottom: 'var(--spacer-l)',
}));
