import { styled } from '@mui/material';

import { MarkdownContainer } from './markdown-container';

export const StyledMarkdownContainer = styled(MarkdownContainer)(
  ({ theme }) => ({
    [theme.breakpoints.up(0)]: {
      marginBottom: '0',
    },

    '& >:first-child': { marginTop: 0 },

    h2: {
      ...theme.typography.h5,
    },

    'h3, h4, h5, h6': {
      ...theme.typography.h6,
    },

    img: {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
    },

    figure: {
      margin: 0,
      marginTop: '16px',

      [theme.breakpoints.up('sm')]: { marginTop: '24px' },
      [theme.breakpoints.up('md')]: { marginTop: '34px' },
      [theme.breakpoints.up('lg')]: { marginTop: '42px' },
    },

    'p:first-child': {
      marginTop: 0,
    },

    'p:last-child': {
      marginBottom: 0,
    },

    table: {
      marginTop: 0,
      marginBottom: 0,
    },

    'figure.table:not(:last-child)': {
      marginBottom: '60px',
    },

    figcaption: {
      ...theme.typography.footnote,
      marginTop: '10px',

      [theme.breakpoints.up('sm')]: { marginTop: '15px' },
      [theme.breakpoints.up('lg')]: { marginTop: '25px' },
    },
  }),
);
