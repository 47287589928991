import { useCallback, useEffect, useRef } from 'react';

export const useAutoStartAnimation = (handlerFn: () => void) => {
  const animation = useRef<ReturnType<typeof setInterval> | null>(null);
  const pause = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearAnimation = () => {
    if (animation.current) {
      clearInterval(animation.current);
    }
  };

  const clearPause = () => {
    if (pause.current) {
      clearTimeout(pause.current);
    }
  };

  const startAnimation = useCallback(() => {
    animation.current = setInterval(() => {
      handlerFn();
    }, 3000);
  }, [handlerFn]);

  const pauseAnimation = useCallback(() => {
    clearAnimation();
    clearPause();

    pause.current = setTimeout(() => {
      startAnimation();
      // restart animation after 10s -> 7s to interval start plus 3s interval duration.
    }, 7_000);
  }, [startAnimation]);

  useEffect(() => {
    clearAnimation();
    startAnimation();

    return () => {
      clearAnimation();
      clearPause();
    };
  }, [startAnimation]);

  useEffect(() => {
    window.addEventListener('pauseAnimation', pauseAnimation);

    return () => {
      window.removeEventListener('pauseAnimation', pauseAnimation);
    };
  }, [pauseAnimation]);

  return {
    pauseAnimation,
  };
};
