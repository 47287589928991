import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

type Props = {
  children: React.JSX.Element;
  title: string;
};

export const MarkdownAccordion = ({ children, title }: Props) => {
  return (
    <Accordion>
      <AccordionSummary>{title}</AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
