export const setTimeoutBrowserFriendly = (
  callback: () => void,
  durationInMs: number,
  setId?: (id: number | null) => void,
) => {
  const start = Date.now();

  const action = () => {
    let requestId = null;
    if (Date.now() - start >= durationInMs) {
      callback();
    } else {
      requestId = requestAnimationFrame(action);
    }
    if (setId) {
      setId(requestId);
    }
  };

  const requestId = requestAnimationFrame(action);
  if (setId) {
    setId(requestId);
  }
};
