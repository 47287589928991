import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Modal } from './modal';
import { useModalAnimation } from './use-modal-animation';

export enum ModalTrigger {
  once,
  exitIntent,
  initiallyOpened,
}

type Props =
  | {
      trigger: ModalTrigger.once;
      modalName: string;
      size?: 'small';
    }
  | {
      trigger?: ModalTrigger.exitIntent | ModalTrigger.initiallyOpened;
      size?: 'small';
    };

export const useModal = (props: Props) => {
  const [showModal, setShowModal] = useState(
    props.trigger === ModalTrigger.initiallyOpened,
  );
  const dialogRef = useRef<HTMLDialogElement>(null);
  useModalAnimation(dialogRef, showModal);

  useEffect(() => {
    if (props.trigger === ModalTrigger.once) {
      if (!localStorage.getItem(props.modalName)) {
        localStorage.setItem(props.modalName, 'true');
        setShowModal(true);
      }
    }
  }, [props]);

  const close = useCallback(() => {
    setShowModal(false);
  }, []);

  const open = () => {
    setShowModal(true);
  };

  const ModalComponent = useMemo(
    () =>
      ({ children }: PropsWithChildren) => (
        <Modal size={props.size} close={close} ref={dialogRef}>
          {children}
        </Modal>
      ),
    [close, props.size],
  );

  return {
    ModalComponent,
    open,
    close,
  };
};
