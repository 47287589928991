import {
  ServiceSegmentMeta,
  ServiceSegmentSubType,
  ServiceSegmentType,
} from '../types';

export const serviceSegments: Record<ServiceSegmentType, ServiceSegmentMeta> = {
  [ServiceSegmentType.businessClothing]: {
    name: 'Businesskleidung',
    uuid: '4028813c1fc61632011fd713d2176477',
  },
  [ServiceSegmentType.protectiveClothing]: {
    name: 'Schutzkleidung',
    uuid: '4028808e51816f590151838e529936c3',
    hasServiceSegmentGroups: true,
    children: {
      '4028808e51816f5901518391a6334f0b':
        ServiceSegmentSubType.chemicalProtection,
      '4028808e51816f590151839255f3511b':
        ServiceSegmentSubType.productProtection,
      '4028808e51816f5901518393155b5365':
        ServiceSegmentSubType.foundryProtection,
      '4028808e51816f590151839367fd549e': ServiceSegmentSubType.glassProtection,
      '4028808e51816f5901518393f1775688':
        ServiceSegmentSubType.heatAndFlameProtection,
      '4028808e51816f5901518394e57059b1': ServiceSegmentSubType.hiVisProtection,
      '4028808e51816f59015183955ffe5b7b':
        ServiceSegmentSubType.weatherProtection,
      '4028808e51816f5901518395d4835d34':
        ServiceSegmentSubType.cementProtection,
    },
  },
  [ServiceSegmentType.serviceClothing]: {
    name: 'Servicekleidung',
    uuid: '4028808e51676b23015171fe4b345843',
  },
  [ServiceSegmentType.workwear]: {
    name: 'Arbeitskleidung',
    uuid: '4028808e51676b23015167837b972f70',
  },
  [ServiceSegmentType.hygieneClothing]: {
    name: 'Hygienekleidung',
    uuid: '4028808e51676b2301517d943fab3e06',
  },
  [ServiceSegmentType.washroomHygiene]: {
    name: 'Waschraumhygiene',
    uuid: '8a9ec78120ebc7c6012186958bea220c',
  },
  [ServiceSegmentType.oilCollectionMats]: {
    name: 'Ölauffangmatten',
    uuid: '4028808e514928e60151587b0014632a',
  },
  [ServiceSegmentType.dirtTrappingMats]: {
    name: 'Schmutzfangmatten',
    uuid: '4028808e514928e6015158348f131f1a',
  },
  [ServiceSegmentType.cleaningCloths]: {
    name: 'Putztuchsystem',
    uuid: '8a9ec78120ebc7c6012186b4519952af',
  },
  [ServiceSegmentType.carCovers]: {
    name: 'KFZ-Schonbezüge',
    uuid: '4028808e514928e601515826e01d7925',
  },
  [ServiceSegmentType.wipingMops]: {
    name: 'Wischmops',
    uuid: '4028808e514928e6015158a594e87a84',
  },
  [ServiceSegmentType.partsCleaner]: {
    name: 'Teilereiniger',
    uuid: '8a9ec7813f862cc2013fa4554d906a9c',
  },
  [ServiceSegmentType.healthClothing]: {
    name: 'Gesundheitskleidung',
    uuid: ServiceSegmentType.healthClothing,
  },
  [ServiceSegmentType.cleanRoom]: {
    name: 'Reinraum',
    uuid: ServiceSegmentType.cleanRoom,
  },
};

export const getServiceSegmentByUUID = (uuid: string) =>
  (
    Object.entries(serviceSegments) as [
      ServiceSegmentType,
      ServiceSegmentMeta,
    ][]
  ).find(([, data]) => data.uuid === uuid);
