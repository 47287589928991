import { styled } from '@mui/material';

export enum Spacing {
  s = 's',
  m = 'm',
  l = 'l',
}

export const StrapiSpacer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'spacing',
})<{ spacing: Spacing }>(({ theme, spacing }) => ({
  width: '100%',
  height: spacingValues.xs[spacing],

  [theme.breakpoints.up('sm')]: {
    height: spacingValues.sm[spacing],
  },

  [theme.breakpoints.up('md')]: {
    height: spacingValues.md[spacing],
  },

  [theme.breakpoints.up('lg')]: {
    height: spacingValues.lg[spacing],
  },
}));

const spacingValues = {
  xs: {
    [Spacing.s]: '10px',
    [Spacing.m]: '30px',
    [Spacing.l]: '50px',
  },
  sm: {
    [Spacing.s]: '10px',
    [Spacing.m]: '40px',
    [Spacing.l]: '60px',
  },
  md: {
    [Spacing.s]: '12px',
    [Spacing.m]: '50px',
    [Spacing.l]: '80px',
  },
  lg: {
    [Spacing.s]: '16px',
    [Spacing.m]: '60px',
    [Spacing.l]: '100px',
  },
};
