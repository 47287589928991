import React from 'react';

import { styled } from '@mui/material';

export type ModalProps = {
  size?: 'small';
  close: () => void;
};

export const Modal = React.forwardRef<
  HTMLDialogElement,
  React.PropsWithChildren<ModalProps>
>(({ children, size, close }, ref) => (
  <div>
    <Dialog ref={ref} size={size}>
      <IconContainer>
        <Icon onClick={close}>
          <CloseIcon />
        </Icon>
      </IconContainer>
      {children}
    </Dialog>
  </div>
));

const CloseIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5858 24.1421L16.2218 30.5061L17.636 31.9203L24 25.5563L30.364 31.9203L31.7782 30.5061L25.4142 24.1421L31.7782 17.7782L30.364 16.364L24 22.7279L17.636 16.364L16.2218 17.7782L22.5858 24.1421Z"
      fill="currentColor"
    />
  </svg>
);

interface ContainerProps {
  size?: 'small';
}

const Dialog = styled('dialog')<ContainerProps>(({ theme, size }) => ({
  width: '335px',
  inset: '0 0 0 0',
  position: 'fixed',
  backgroundColor: 'white',
  padding: 'var(--spacer-m) var(--spacer-l) var(--spacer-l)',
  animation: 'var(--transition-duration) ease-out show-content',
  border: 'none',

  '&::backdrop': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    animation: 'var(--transition-duration) ease-out show',
    backdropFilter: 'blur(75px)',
    transition:
      'opacity var(--transition-duration) linear, backdrop-filter var(--transition-duration)',

    '@keyframes show': {
      from: { opacity: 0, backdropFilter: 'none' },
      to: { opacity: 1, backdropFilter: 'blur(75px)' },
    },
  },

  '@keyframes show-content': {
    from: { transform: 'translateY(50%)' },
    to: { transform: 'translateY(0)' },
  },

  [theme.breakpoints.up('sm')]: {
    width: '673px',
  },

  [theme.breakpoints.up('md')]: {
    width: size === 'small' ? '673px' : '695px',
  },

  [theme.breakpoints.up('lg')]: {
    width: size === 'small' ? '673px' : '920px',
  },

  '&.hide': {
    transition: 'transform var(--transition-duration) ease-in',
    transform: 'translateY(50%)',

    '&::backdrop': {
      transition:
        'opacity var(--transition-duration) ease-in, backdrop-filter var(--transition-duration) ease-in',
      opacity: 0,
      backdropFilter: 'none',
    },
  },
}));

const IconContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  marginBottom: 'var(--spacer-m)',
});

const Icon = styled('button')({
  color: 'var(--color-dark-coal)',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',

  '&:hover': {
    color: 'var(--color-light-red)',
  },
});
